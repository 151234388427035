.rbc-event {
    height: auto !important;
    background-color: transparent !important;
}

.rbc-event > .rbc-event-content > .celda-visita {
    background-color: #76b7ed !important;
    border-radius: 6px;
}

.rbc-event > .rbc-event-content > .celda-reu {
    background-color: #9de09e !important;
    border-radius: 6px;
}