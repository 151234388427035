.thumbnail-documento {
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-color: #e0e0e0 !important;
}
.thumbnail-aceptacion-carta-oferta {
    background: url("https://i.ibb.co/ccGswgb/thumbnailaceptacioncartaoferta.png");
}

.thumbnail-carta-oferta {
    background: url("https://i.ibb.co/nMGVS0j/thumbnailaceptacioncartaoferta-1.png");
}

.thumbnail-orden-venta {
    background: url("https://i.ibb.co/171jcN4/thumbnailordenventa.png");
}

.thumbnail-orden-arriendo {
    background: url("https://i.ibb.co/syhm2wv/thumbnailordenarriendo.png");
}

.thumbnail-orden-visita {
    background: url("https://i.ibb.co/0qmcpzJ/thumbnailordenvisita.png");
}

.thumbnail-orden-canje {
    background: url("https://i.ibb.co/kXmHtK9/thumbnailordencanje.png");
}

.thumbnail-reporte {
    background: url("https://i.ibb.co/MV9sB0d/reporte-thumbnail.png");
}

.reporte {    
    height: 120px;
}

.tituloReporte {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid #e0e0e0;
    padding: 0.3rem 0.5rem;
  }

.document-card {
    width: 261px;
    border: 1px solid #e0e0e0;
    border-radius: 0px 0px 8px 8px;
}