

.difusion {
    height: 94px;
    width: 261px;
    background: #e0e0e0;
    border-radius: 0px;
}
.thumbnail-difusion {
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-color: #e0e0e0 !important;
}
.thumbnail-planes-arriendo {
    background: url("https://i.ibb.co/0M4z7kP/thumbnailplanesarriendo.png");
}

.thumbnail-planes-venta {
    background: url("https://i.ibb.co/XXbtRHF/thumbnailplanesdeventa.png");
}

.thumbnail-tarjeta-presentacion {
    background: url("https://i.ibb.co/MhNSsb3/thumbnailtarjetapresentacion.png");
}

.thumbnail-tarjeta-prop {
    background: url("https://i.ibb.co/vw6fRWG/thumbnailtarjetaprop.png");
}

.titulo-difusion {
    position: relative;
    display: flex;
    align-items: center;
    top: 94px;
    background-color: #ffffff;
    width: 261px;
    height: 55px;
    border: 1px solid #e0e0e0;
    border-radius: 0px 0px 8px 8px;
    padding: 0rem 0.3rem;
}