body {
  background: #ffffff;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  height: 100%;
}