.contenedor {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.containerNav {
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.sideBar {
  flex: 1;
  color: #555;
  height: calc(100vh - 50px);
  max-width: 250px;
  position: sticky;
  padding-top: 20px;
  top: 50px;
  border-right: solid 1px;
}

.sideBarMenu {
  margin-bottom: 10px;
  text-align: left;
  margin-top: 100px;
}

.sideBarList {
  padding: 0;
}

#active {
  color: #00a62b;
  border-left: solid 8px;
}

.logo {
  max-width: 200px;
  margin-left: 10px;
  margin-top: 10px;
}

.contenido {
  padding: 10px;
  padding-left: 20px;
  flex: 4;
  width: 100vw;
  background-color: #ffffff;
  /*overflow: auto;*/
}

.header {
  text-align: left;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  font-size: 35px;
}

.boton2 {
  padding: 0.5rem 2rem;
  background-color: #c9ecd2;
  border: none;
  border-radius: 8px;
  color: #00a62b;
  font-size: large;
  transition: 0.3s;
}

.boton:hover,
.botonMatch:hover {
  background: #008d24;
}
.boton2:hover,
.botonMatch:hover {
  background: #a6e0b5;
}

.boton-gris {
  padding: 0.5rem 2rem;
  background-color: #F2F2F2;
  border: none;
  border-radius: 8px;
  color: #6E6E6F;
  font-size: large;
  transition: 0.3s;
}

.boton-gris:hover {
  background-color: #d3d3d3;
  color: #545454;
}

.buscar {
  height: 44px;
  border-radius: 4px;
  border: none;
  padding-left: 10px;
  background-color: #f2f2f2;
  margin-right: 5px;
}

.seleccionador {
  height: 44px;
  border: none;
  margin-right: 5px;
  background-color: #ffffff;
}

.seleccionador:hover {
  border: solid 1px;
  border-radius: 4px;
  outline: none;
}

.barraNav {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.barraNav input {
  max-width: 120px;
  margin: 5px;
}

.main {
  padding-top: 30px;
}
.main2 {
  padding-top: 30px;
}

.link {
  color: #008d24;
  text-decoration: underline;
  cursor: pointer;
}

.miSelect {
  height: 32px;
  width: 100%;
  border: solid 0.5px;
  border-color: #a0a0a0;
  outline: none;
  border-radius: 4px;
}

.miTextarea {
  width: 100%;
  border: solid 0.5px;
  border-color: #a0a0a0;
  outline: none;
  border-radius: 4px;
}

.miSelectChico {
  height: 32px;
  width: 87px;
  border: solid 0.5px;
  border-color: #a0a0a0;
  outline: none;
  border-radius: 4px;
}

.mySelect {
  height: 30px;
  width: 196px;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: #d9d9d9;
}

.miSelect2 {
  height: 30px;
  width: 123px;
  border-radius: 4px;
  font-size: 13px;
  outline: none;
}
.miButton {
  border: solid 1px;
  border-radius: 6px;
  background-color: #ffffff;
  color: #008d24;
  border-color: #008d24;
  transition: 500ms;
}

.miButton:hover {
  background-color: #97c2a1;
}

.milabel {
  display: block;
  text-align: left;

  margin-top: 7px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 112% */

  letter-spacing: 0.16px;

  color: #000000;
}
.miInput {
  outline: none;
  border: solid 0.5px;
  width: 100%;
  border-radius: 4px;
  color: #a0a0a0;
}

#active1 {
  color: #008d24;
}

#notActive1 {
  color: #a0a0a0;
}

.confirmada {
  background: #c9ecd2;
  color: green;
  border-radius: 4px;
  padding: 4px 0px;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}

.pendiente {
  background: #ffe8a3;
  color: #cc7a00;
  border-radius: 4px;
  padding: 4px 0px;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}

.cancelada {
  background: #fed4d4;
  color: #7c0000;
  border-radius: 4px;
  padding: 4px 0px;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}

.otrolabel {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
  color: #a0a0a0;
}

.otrolabel2 {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
}

.otrolabel3 {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
}

.headerModal {
  display: flex;
  justify-content: center;
}

.noMostrar {
  display: none;
}

.barra {
  background-color: #ffffff;
}

.sidebarHeight {
  height: 100%;
}

.filaOscura {
  background-color: #fafafc;
}

.headTabla {
  color: #a0a0a0;
  text-align: left;
}

.iconSwitch {
  margin-right: 15px;
  margin-bottom: 10px;
}

.fila {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

h4 {
  text-align: left;
}

.seccion {
  margin-bottom: 30px;
  text-align: left;
}

.selectFullWidth {
  height: 30px;
  border-radius: 4px;
  width: 100%;
  outline: none;
  border-color: #a0a0a0;
}

.select {
  height: 30px;
  border-radius: 4px;
}

.campoPlan {
  text-align: left;
  width: 100%;
}

#noMostrar {
  display: none;
}

.campofullwidth {
  width: 100%;
}
.fullwidth {
  width: 100%;
}

.newInput {
  outline: none;
  border: solid 0.5px;
  border-radius: 4px;
  border-color: #a0a0a0;
}

.campo {
  width: 150px;
  margin-right: 10px;
}

.campo2 {
  margin-right: 10px;
}

.campoCheck {
  width: 150px;
  margin-right: 10px;
  text-align: left;
}

.masCaracteristicas {
  width: 100%;
}

#noVisible {
  display: none;
}

.opciones {
  display: flex;
  border-bottom: solid 1px;
}

.opciones h3 {
  margin-top: 30px;
  margin-right: 20px;
  margin-bottom: 0;
}

.opciones h3:hover {
  cursor: pointer;
  font-weight: bold;
}

.opciones .opcionActividades {
  color: #a0a0a0;
}

.infoPerfil {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

a {
  text-decoration: none;
  color: #212529;
}

p {
  font-size: 16px;
  font-weight: 400;
}

.barraFiltro {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  padding-left: 0;
  padding-right: 0;
}

.comprador a {
  color: #a0a0a0;
}

.vendedor a {
  margin-right: 30px;
  color: black;
}

.infoProspecto {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.gris {
  color: #a0a0a0;
}

.labelInfo {
  font-size: larger;
  font-weight: 700;
}

.card {
  padding: 20px;
  padding-top: 0px;
  margin-bottom: 20px;
  box-shadow: 0.5px 0.5px;
}

.codigoProp {
  color: #00a62b;
}

.barraNav2 {
  display: flex;
  justify-content: right;
  margin-bottom: 15px;
}

.requerimientos {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.14);
}

.botones {
  display: flex;
}

.botonMatchDis {
  padding: 10px 20px;

  background: #ffffff;
  border-radius: 0px 0px 8px 0px;
  border: 1px solid #e0e0e0;
  color: black;
  font-size: 16px;
  font-weight: 500;
  transition: 500ms;
}

.botonMatch {
  padding: 10px 20px;
  background: #c9ecd2;
  color: black;
  border-radius: 0px 0px 0px 8px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  font-weight: 500;
  transition: 500ms;
}

.titulo {
  text-align: left;
  margin-top: 10px;
}

.bubble {
  background-color: #ec000e;
  color: white;
  border-radius: 15px;
  padding: 2px 8px;

  position: relative;
}

.icon {
  margin-right: 15px;
  margin-bottom: 10px;
}

.anadirPropi {
  background: #ffffff;
  border-radius: 8px;
  border: solid 1px;
  border-color: #008d24;
  color: #008d24;
  font-size: 20px;
}

.matches {
  margin-top: 20px;
}

.datos {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.infoGeneral {
  display: flex;
  gap: 50px;
  margin-bottom: 60px;
}

.alignLeft {
  text-align: left;
}

.flex {
  display: flex;
}

.caracteristicas {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10rem;
}

.mas {
  display: flex;
  gap: 40px;
}

.masWidth {
  width: 1000px;
}

.marginBot {
  margin-bottom: 50px;
}

.imagen {
  height: 174.3686065673828px;
  width: 262px;
  border-radius: 0px;
}

.imagenes {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.agregarImagen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 174.3686065673828px;
  width: 262px;
  border-radius: 0px;
  background-color: #f2f2f2;
}

.headerDifusion {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: solid 0.5px #a0a0a0;
}
.headerDifusion h1 {
  font-size: 35px;
}

.descRecursos {
  font-size: medium;
  font-weight: 500;
  color: #a0a0a0;
}

.otros {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.contenidoRecursos {
  height: 174.3686065673828px;
  width: 262px;
  border: solid 0.5px #a0a0a0;
  border-radius: 8px;
  background-color: #f2f2f2;
}

.styleDownload {
  border: solid 1px;
  border-radius: 4px;
  margin-right: 5px;
  background: #3d3d3d;
  color: white;
}

.metrica {
  border: solid 1px #a0a0a0;
  border-radius: 8px;
  box-shadow: 1px 1px rgb(1, 1, 1, 0.5);
  padding: 12px;
  text-align: left;
  width: 100%;
  height: 100%;
  display: table;
}

.metricas {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.metrica-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* identical to box height */

  /* Green Propins */

  color: #00a62b;
}

.tituloMetrica {
  color: #a0a0a0;
}

.tipoMatch {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  display: block;
}
.fontBotonMatch {
  font-size: 16px;
  font-weight: 500;
}

.rbc-event {
  background-color: #a6e0b5 !important;
  color: black !important;
  font-size: 12px !important;
  height: 50px;
  border-radius: 0px 8px 8px 0px !important;
  width: 90% !important;
}

.rbc-active {
  background-color: #c9ecd2 !important;
  color: #008d24 !important;
}

.crearDocum {
  background-color: #00a62b;
  position: relative;
  top: 10px;
  left: 55px;
  color: #ffffff;
  border-radius: 8px;
  width: 136px;
  height: 29px;
  border: none;
  font-size: 10pt;
  margin-bottom: 0.5rem;
}

.MuiFormLabel-root,
.MuiFormControlLabel-label {
  font-family: "Inter", sans-serif !important;
}

.subtitle {
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #000000;
}

.main-btn {
  gap: 8px;
  /* background: #00A62B; */
  border-radius: 8px !important;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px;
  text-transform: none !important;
  color: #ffffff;
}

.dropzone {
  border-radius: 6px;
  background-color: #dedede;
  padding: 8px 8px;
}

.swal2-container {
  z-index: 999999 !important;
}

.back-btn {
  color: black !important;
}

.thumbnail-icon {
  display: inline-flex;
  align-items: center;
  top: 30%;
  margin: auto;
  width: 50px;
  justify-content: center;
  height: 50px;
  background-color: #d9d9d9;
  position: relative;
  border-radius: 100%;
  border: 1px solid white;
}

.thumbnail-icon-sm {
  display: inline-flex;
  align-items: center;
  top: 30%;
  margin: auto;
  width: 30px;
  justify-content: center;
  height: 30px;
  background-color: #d9d9d9;
  position: relative;
  border-radius: 100%;
  border: 1px solid white;
}

.centered-icon-div {
  justify-content: center;
  display: flex;
  top: 30%;
  position: relative;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.metrica-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a0a0a0;
}

.metrica-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #3d3d3d;
  text-align: center;
}

.bold-subtitle {
  font-size: 22pt;
  font-weight: 600;
}

.dropzonenew {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 100%;
}

.selected-file-title {
  font-weight: 600;
}

.btn-arrendar-vender-base {
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  width: 100%;
  background: #b0b0b0 !important;
  border-radius: 4px;
  opacity: 1;
  color: white;
  border: none;
  border-radius: 8px;
}

.btn-arrendar-vender-inactive {
  background-color: #b0b0b0 !important;
}

.btn-arrendar-vender-active,
.btn-arrendar-vender-active:hover .btn-arrendar-vender-base:hover {
  background-color: #00a72c !important;
}

.full-height {
  height: 100%;
}

.color-red {
  color: red;
}

.iconbutton-habilitado:hover {
  color: #3F51B5;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-100px {
  margin-bottom: 100px;
}

/* RESPONSIVE */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .boton {
    padding: 6px 30px;
    background: #00a62b;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 12pt;
  }

  .anadirPropi {
    padding: 6px 30px;
    background: #ffffff;
    border-radius: 8px;
    border: solid 1px;
    border-color: #008d24;
    color: #008d24;
    font-size: 12pt;
  }

  .subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #000000;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
  .boton {
    padding: 6px 30px;
    background: #00a62b;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: large;
  }

  .anadirPropi {
    background: #ffffff;
    border-radius: 8px;
    border: solid 1px;
    border-color: #008d24;
    color: #008d24;
    font-size: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
  .boton {
    padding: 6px 30px;
    background: #00a62b;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: large;
  }

  .anadirPropi {
    background: #ffffff;
    border-radius: 8px;
    border: solid 1px;
    border-color: #008d24;
    color: #008d24;
    font-size: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  
  .boton {
    padding: 6px 30px;
    background: #00a62b;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: large;
  }

  .anadirPropi {
    background: #ffffff;
    border-radius: 8px;
    border: solid 1px;
    border-color: #008d24;
    color: #008d24;
    font-size: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
  .boton {
    padding: 6px 30px;
    background: #00a62b;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: large;
  }

  .anadirPropi {
    background: #ffffff;
    border-radius: 8px;
    border: solid 1px;
    border-color: #008d24;
    color: #008d24;
    font-size: 20px;
  }
}

.MuiTablePagination-toolbar {
  align-items: baseline !important;
}

.justify-content-center {
  justify-content: center;
}
.red {
  color: #FF0000;
}
.bg-red {
  background-color: #FF0000 !important;
}
.d-none {
  display: none;
}